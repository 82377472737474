import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function pointQuery() {
  return omniFetch({
    url: config.v1Path + config.pointQueryPath,
    method: "get",
    params: {}
  })
}

export function pointQueryLog() {
  return omniFetch({
    url: config.v1Path + config.pointQueryLogPath,
    method: "get",
    params: {}
  })
}

export function pointReferralLog({type, pageSize, pageIndex}) {
  return omniFetch({
    url: config.v1Path + config.pointReferralLogPath,
    method: "post",
    params: {type, pageSize, pageIndex} // all success
  })
}

export function pointReferralCodeCheck({invitationCode}) {
  return omniFetch({
    url: config.v1Path + config.pointReferralCodeCheckPath,
    method: "post",
    params: {invitationCode}
  })
}
